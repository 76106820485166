import React from 'react';

export function newlineRender(string, truncate_len = 0) {
    return string.trim().split('\n').map(function (item) {
        return (<>{truncate(item, truncate_len)}<br/></>)
    });
}

export function truncate(str, len = 20) {
    if (len && str.length > len) {
        return str.substring(0, len - 1) + '…';
    }
    return str
}

export function booleanToText(input){
        if (input === true){
            return "Yes";
        }
        else if (input === false){
            return "No";
        }
        else{
            return "?";
        }
}

export function networkSoftware(input) {
    if (input[0] === "M" && ['R','S','X'].includes(input[1])){
        return "out-of-date";
    }
    else{
        return input;
    }
}
