import KPIGrid from "./components/kpi_grid";
import React from "react";
import VulnTrackerTable from "./components/tables/table_vuln_tracker";
import MerakiTable from "./components/tables/table_meraki";
import GitHubTable from "./components/tables/table_github_main";
import MonitoringTable from "./components/tables/table_system_monitoring";
import AWSTable from "./components/tables/table_aws_main";
import DomainTable from "./components/tables/table_domains";
import AWSConfig from "./components/tables/table_aws_config";
import AWSConfigAccountTable from "./components/tables/table_aws_config_account_table";
import SophosTable from "./components/tables/table_sophos";

export const SHOW_LOGO = true;

export function get_url(){
    if (process.env.NODE_ENV === 'production') {
        // Reuse the schema:host:port already in use
        return '/'
    }
    else{
        // Direct to development Flask server
        return 'http://127.0.0.1:5000/'
    }
}

export const routes = [
    {route: '/', view: (<KPIGrid/>), disable_csv: true},
    {route: '/domains', view: (<DomainTable/>)},
    // {route: '/vulns', view: (<VulnTrackerTable/>)},
    {route: '/office_networks', view: (<MerakiTable/>)},
    {route: '/github', view: (<GitHubTable/>)},
    {route: '/monitoring', view: (<MonitoringTable/>)},
    {route: '/sophos', view: (<SophosTable/>)},
    {route: '/aws', view: (<AWSTable/>), disable_csv: true},
    {route: '/aws_config', view: (<AWSConfig/>), disable_csv: true},
    {route: '/aws_config_account', view: (<AWSConfigAccountTable/>), disable_csv: true}
]

export const STRING_PRODUCTION_DOMAINS = "The percentage of production mailing domains with all security controls enabled.";
export const STRING_ALL_DOMAINS = "The percentage of mailing domains with all security controls enabled.";
export const STRING_IP_ADDRESS = "It is important to identify whether an Ebury domain resolves to an IP address in a private network as this would provide an insight into Ebury's network topology.";
export const STRING_DMARC = "DMARC, or Domain-based Message Authentication, Reporting & Conformance, is a security protocol that helps protect email senders and recipients from email spoofing. It works by allowing the owner of a domains to publish a policy in their DNS records specifying which mechanisms (e.g. SPF, DKIM) are used to authenticate emails sent from their domains. Receiving mail servers can then check the DMARC policy of the sender's domains to determine whether or not to trust the email.";
export const STRING_DKIM = "DKIM, or DomainKeys Identified Mail, is a security protocol used to authenticate email senders. It works by adding a digital signature to the headers of an email, which can be verified by the receiving mail server to ensure that the email has not been tampered with in transit. The digital signature is created using a private key that is kept by the sender and a public key that is published in the DNS records of the sender's domains. When an email is received, the receiving mail server can use the public key to verify the signature and confirm that the email is genuine.";
export const STRING_SPF = "SPF, or Sender Policy Framework, is a security protocol used to authenticate email senders. It works by allowing the owner of a domains to publish a list of authorized mail servers in their DNS records. When an email is received, the receiving mail server can check the SPF record to see if the server that sent the email is on the list of authorized servers. If it is not, the email may be marked as spam or rejected.";
export const STRING_BRANCH_PROTECTIONS = "Branch protections are settings that can be enabled to protect repositories from merging non-reviewed changes to master. The previous metric shows the repositories with active branch protections.";
export const STRING_ENFORCE_ADMINS = "Setting that disables the administrator user capabilities to bypass branch protection settings.";
export const STRING_DISMISS_STALE_REVIEWS = "Setting that ensures new reviewable commits pushed to a matching branch will dismiss pull request review approvals.";
export const STRING_REQUIRE_CODE_OWNER_REVIEWS = "Setting to require a branch to pass a review from a certain number of the repository's code owners.";
export const STRING_REQUIRE_LAST_PUSH_APPROVAL = "Setting that requires latest changes are reviewed and approved before merging.";
export const STRING_STATUS_CHECK_STRICT = "Setting to require the repository's status checks to be passed.";