import React from 'react';

import {
    Badge
} from '@tremor/react';

import {get_url} from '../const.js'

export default class ConnectionTest extends React.Component {

    // A very simple component that displays the connection state
    // i.e. Can the front-end talk to the backend.

    state = {
        api_state: 'connecting'
    };

    componentDidMount() {
        fetch(get_url()+'api/')
            .then((response) => response.json())
            .then((externalData) => {
                this.setState({api_state: 'connected'})
            })
            .catch((error) => {
                this.setState({api_state: 'error'})
                console.error(error);
            });
    }

    render() {
        switch (this.state.api_state){
            case 'error':
                return (<Badge className="mt-2" color="red" tooltip="Error: Could not connect to the API">✗ Not connected</Badge>);
            case 'connected':
                return (<Badge className="mt-2" color="green" tooltip="Connected to the API, all systems go!">✓ Connected</Badge>);
            case 'connecting':
            default:
                return (<Badge className="mt-2" color="blue" tooltip="Not yet connected to the API, hold on.">- Connecting ...</Badge>);
        }
    }
}