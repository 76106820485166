import React from 'react';

import {
    Badge,
    Card,
    Flex,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title
} from '@tremor/react';

import {NoDataComponent, LoadingComponent} from '../status_components'
import {get_url} from '../../const.js'
import withLocation from "../../withLocation";
import {getFilterFromGetParams} from "../utils/query_utils";


class GitHubBranchesTable extends React.Component {

    state = {
        branches: null,
        metrics: null,
        nameFilter: getFilterFromGetParams(this.props, 'name', null)
    };

    col_map = {'pass': 'emerald', 'warning': 'yellow', 'fail':'red'};

    componentDidMount() {
        fetch(get_url() + 'api/github_branches')
            .then((response) => response.json())
            .then((branches) => {
                this.setState({branches: branches})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.branches === null || this.state.metrics === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        }
        else if (this.state.branches.length === 0 || this.state.metrics === 0) {
            return (
                <NoDataComponent/>
            );
        }
        else {

            const github_branches = this.state.metrics.github_branches;
            // Render real UI ...
            let active_branches = this.state.branches
                .filter(item => !this.state.nameFilter || item.repository_name.includes(this.state.nameFilter));

            return (
                <>
                    <Flex justifyContent={"start"}>
                        <Title>GitHub branches: {active_branches.length}</Title>
                        <Text>&nbsp;/ {github_branches.branches}</Text>
                    </Flex>
                    {this.renderFilters()}

                    <Table className="mt-5">

                        {this.renderHeader()}

                        <TableBody>
                            {active_branches.map((item) => this.renderRow(item))}
                        </TableBody>
                    </Table>
                </>
            );
        }
    }

    renderFilters() {

        return (
            <div>
                <Text>Select filters</Text>

                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <input type="text" placeholder="Filter" onChange={e => {
                            this.setState({nameFilter: e.target.value})
                        }
                        }/>

                    </Flex>
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell>
                        Repository
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Branch
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Enforce Admins
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Require Code<br /> 
                        Owner Reviews
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Require Last<br /> 
                        Push Approval
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Required Approving<br /> 
                        Review Count
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Status Check Strict 
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }
    
    renderState(state) {
        if (state) {
            return (<Badge color="green" tooltip="Enabled">✓</Badge>)
        } else {
            return (<Badge color="red" tooltip="Disabled">✗</Badge>)
        }
    }
    
    renderRow(branch) {
        return (
            <TableRow key={branch.name}>
                <TableCell>
                    {branch.repository_name}
                </TableCell>
                <TableCell className="text-center">
                    {branch.branch_name}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderState(branch.enforce_admins)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderState(branch.pr_require_code_owner_reviews)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderState(branch.pr_require_last_push_approval)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderState(branch.pr_required_approving_review_count)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderState(branch.status_check_strict)}
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(GitHubBranchesTable)
