import React from 'react';

import {
    Grid
} from '@tremor/react';

import {get_url} from '../const.js'
import {
    KPIDecorationColor,
    VulnsStackedBarChart, KPICardMetrics, BitsightLineGraph, KPICardMetricsWithPercentIncrease
} from "./kpi_components";

import { ComputerDesktopIcon, ClipboardDocumentCheckIcon, LockClosedIcon, EllipsisHorizontalCircleIcon, EnvelopeIcon, EyeIcon,
    BoltIcon, ArrowPathIcon, BriefcaseIcon, LockOpenIcon, ExclamationTriangleIcon, ExclamationCircleIcon, Cog8ToothIcon, WrenchScrewdriverIcon, ShieldCheckIcon, CogIcon, ShieldExclamationIcon} from "@heroicons/react/24/outline";
import {LoadingComponent} from "./status_components";

export default class KPIGrid extends React.Component {

    state = {
        metrics: null,
        bitsight: null,
        kpis: null
    };

    componentDidMount() {
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({metrics: metrics})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/bitsight')
            .then((response) => response.json())
            .then((bitsight) => {
                this.setState({bitsight: bitsight})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/cloud/kpis')
            .then((response) => response.json())
            .then((kpis) => {
                this.setState({kpis: kpis})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.metrics === null || this.state.bitsight === null || this.state.kpis === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else {
            // Render real UI ...

            const domains = this.state.metrics.domains;
            const meraki = this.state.metrics.meraki;
            // const vuln_tracker = this.state.metrics.vuln_tracker;
            const github_repositories = this.state.metrics.github_repositories;
            const github_branches = this.state.metrics.github_branches;
            const system_monitoring = this.state.metrics.systems_monitored;
            const aws_config_summary = this.state.metrics.config_compliance_summary;
            const aws_config_first_of_month = this.state.metrics.config_compliance_first_of_month;
            const conformance_pack_summary = this.state.kpis.config_conformance_pack_metrics.overall_conformance_pack_percent
            const sophos = this.state.metrics.sophos;
            const github_branch_protection = github_branches.branches/github_branches.repositories

            let mailing_has_mx_spf_dkim_dmarc = (domains.all_mailing_domains.has_mx_spf_dkim_dmarc/domains.all_mailing_domains.total_mailing)*100
            let percent_domain_auto_renew = (domains.expiration_metrics.num_auto_renew / domains.expiration_metrics.num_domains_with_expiration) * 100

            let percent_up_to_date_meraki = (meraki.software_versions[0].all_up_to_date/meraki.total_meraki)*100
            let percent_meraki_offices = (meraki.total_meraki_offices / meraki.total_offices)*100

            let percent_systems_monitored = (system_monitoring.total_critical_systems_monitored / system_monitoring.total_critical_systems)*100
            let percent_systems_automated = (system_monitoring.automated_import.automated / system_monitoring.total_critical_systems)*100
            let percent_siem_monitored = ((system_monitoring.siem.securonix + system_monitoring.siem.opensearch + system_monitoring.siem.elk_stack) / system_monitoring.total_critical_systems) * 100

            return (
                <Grid numColsMd={3} className="gap-x-6 gap-y-6 mt-6">

                    { KPICardMetrics({
                        title: 'Expired Domains',
                        title_metric: domains.expiration_metrics.num_expired,
                        title_icon: ExclamationCircleIcon,
                        title_icon_color: KPIDecorationColor(domains.expiration_metrics.num_expired, 1, 2),
                        title_location: '/domains?expired=true',
                        color: KPIDecorationColor(domains.expiration_metrics.num_expired, 1, 2),
                        items: [
                            { icon: ExclamationTriangleIcon, icon_color: 'yellow', metric: domains.expiration_metrics.num_expiring, title: 'Expiring (3 months)', location:'/domains?expiring=true' },
                            { icon: ExclamationTriangleIcon, icon_color: 'yellow', metric: domains.expiration_metrics.tls_expiring, title: 'TLS Cert Expiring (30 days)', location:'/domains?tls_expiring=true'},
                            { icon: WrenchScrewdriverIcon, icon_color: 'blue', metric: percent_domain_auto_renew.toFixed(0)+"%", title: 'Auto-Renew Enabled', location:'/domains?autorenew=false'}
                        ]
                    }) }

                    { KPICardMetrics({
                        title: 'Failing Domains',
                        title_metric: domains.domain_status.fail,
                        title_metric_total: domains.num_domains,
                        title_icon: ExclamationCircleIcon,
                        title_icon_color: KPIDecorationColor(domains.domain_status.fail, 1, 2),
                        title_location: '/domains?status=fail',
                        color: KPIDecorationColor(domains.domain_status.fail, 1, 2),
                        items: [
                            { icon: ExclamationTriangleIcon, icon_color: 'yellow', metric: domains.domain_status.warning, title: 'Warnings', location:'/domains?status=warning' },
                            {}
                        ]
                    }) }

                    { KPICardMetrics({
                        title: 'Secure Mailing Domains',
                        title_metric: mailing_has_mx_spf_dkim_dmarc.toFixed(0)+"%",
                        title_icon: EnvelopeIcon,
                        title_icon_color: KPIDecorationColor(mailing_has_mx_spf_dkim_dmarc.toFixed(0), 95, 90, true),
                        title_location: '/domains?status=warning,fail&mx=true',
                        color: KPIDecorationColor(mailing_has_mx_spf_dkim_dmarc.toFixed(0), 95, 90, true),
                        items: [
                            { icon: ExclamationTriangleIcon, icon_color: 'yellow', metric: domains.all_mailing_domains.has_mx_spf_dkim_dmarc, total: domains.all_mailing_domains.total_mailing, title: 'has SPF, DKIM, DMARC', location:'/domains?status=warning'},
                            { icon: EyeIcon, icon_color: 'blue', metric: domains.all_mailing_domains.ip_address_public, total: domains.all_mailing_domains.total_mailing, title: 'has public IP', location:'/domains?status=warning' },
                            ]
                    }) }

                    { KPICardMetrics({
                        title: 'Systems Monitored',
                        title_metric: percent_systems_monitored.toFixed(0)+"%",
                        title_icon: ShieldExclamationIcon,
                        title_icon_color: KPIDecorationColor(percent_systems_monitored.toFixed(0), 95, 90, true),
                        title_location: '/monitoring?monitored=false',
                        color:  KPIDecorationColor(percent_systems_monitored.toFixed(0), 95, 90, true),
                        items: [
                            { icon: CogIcon, icon_color: 'yellow', title: 'Automatic Ingress', metric: percent_systems_automated.toFixed(0)+"%", location:'/monitoring?automated=false' },
                            { icon: ShieldCheckIcon, icon_color: 'green', title: 'Monitored By SIEM', metric: percent_siem_monitored.toFixed(0)+"%", location:'/monitoring?siem=false' },
                        ]
                    }) }

                    { KPICardMetrics({
                        title: 'Updated Office Networks',
                        title_metric: percent_up_to_date_meraki.toFixed(0)+"%",
                        title_icon: ArrowPathIcon,
                        title_icon_color: KPIDecorationColor(percent_up_to_date_meraki.toFixed(0), 90, 80, true),
                        title_location: '/office_networks?up_to_date=false' ,
                        color:  KPIDecorationColor(percent_up_to_date_meraki.toFixed(0), 90, 80, true),
                        items: [
                            { icon: BriefcaseIcon, icon_color: 'yellow', title: 'Offices with Network', metric: percent_meraki_offices.toFixed(0)+"%", location:'/office_networks' },
                            {}
                        ]
                    }) }

                    {/*{ KPICardMetrics({*/}
                    {/*    title: 'Open High & Critical Vulnerabilities',*/}
                    {/*    title_metric: vuln_tracker.open.high_critical,*/}
                    {/*    title_icon: ExclamationTriangleIcon,*/}
                    {/*    title_icon_color: KPIDecorationColor(vuln_tracker.open.high_critical, 0, 1),*/}
                    {/*    title_location: '/vulns?severity=high,critical&status=received,triaged,breached',*/}
                    {/*    color: KPIDecorationColor(vuln_tracker.open.high_critical, 0, 1),*/}
                    {/*    items: [*/}
                    {/*        { icon: LockOpenIcon, icon_color: 'green', metric: vuln_tracker.open.total, total: vuln_tracker.all.total, title: 'Total Open', location:'/vulns?status=received,triaged,breached' },*/}
                    {/*        {}*/}
                    {/*    ]*/}
                    {/*}) }*/}

                    {/*{ KPICardMetrics({*/}
                    {/*    title: 'Breached Vulnerabilities',*/}
                    {/*    title_metric: vuln_tracker.num_breached,*/}
                    {/*    title_icon: BoltIcon,*/}
                    {/*    title_icon_color: KPIDecorationColor(vuln_tracker.breached.HIGH + vuln_tracker.breached.CRITICAL, 0, 1),*/}
                    {/*    title_location: '/vulns?status=breached',*/}
                    {/*    color: KPIDecorationColor(vuln_tracker.breached.HIGH + vuln_tracker.breached.CRITICAL, 0, 1),*/}
                    {/*    items: [*/}
                    {/*        { icon: ExclamationTriangleIcon, icon_color: 'red', metric: vuln_tracker.breached.HIGH + vuln_tracker.breached.CRITICAL, total: vuln_tracker.num_breached, title: 'High & Critical', location:'/vulns?status=breached&severity=high,critical'},*/}
                    {/*        { icon: ExclamationTriangleIcon, icon_color: 'yellow', metric: vuln_tracker.breached.LOW + vuln_tracker.breached.MEDIUM, total: vuln_tracker.num_breached, title: 'Low & Medium', location:'/vulns?status=breached&severity=low,medium'}*/}
                    {/*    ]*/}
                    {/*}) }*/}

                    { KPICardMetrics({
                        title: 'Branch Protected Repos',
                        title_metric: ((github_branch_protection)*100).toFixed(0)+"%",
                        title_icon: Cog8ToothIcon,
                        title_icon_color: KPIDecorationColor(github_branch_protection, 0.90, 0.85, true),
                        title_location: '/github',
                        color: KPIDecorationColor(github_branch_protection, 0.90, 0.85, true),
                        items: [
                            { icon: EllipsisHorizontalCircleIcon, icon_color: 'yellow', metric: github_repositories.total_outdated, total: github_repositories.total_repositories, title: 'Stale Repos (6 months)', location:'/github' }
                        ]
                    })}

                    { KPICardMetrics({
                            title: 'Endpoints Protected',
                            title_metric: (((sophos.total_endpoints-sophos.total_missing_sophos)/sophos.total_endpoints)*100).toFixed(0)+"%",
                            title_icon: LockClosedIcon,
                            title_icon_color: KPIDecorationColor(sophos.total_endpoints-sophos.total_missing_sophos, 0.95, 0.90, true),
                            title_location: '/sophos',
                            color: KPIDecorationColor((sophos.total_endpoints-sophos.total_missing_sophos)/sophos.total_endpoints, 0.95, 0.90, true),
                            items: [
                                { icon: ComputerDesktopIcon, icon_color: 'blue', metric: sophos.no_sophos_windows, title: 'Windows not protected', location:'sophos?os=windows' },
                                { icon: ComputerDesktopIcon, icon_color: 'blue', metric: sophos.no_sophos_linux, title: 'Linux not protected', location:'sophos?os=linux' },
                                { icon: ComputerDesktopIcon, icon_color: 'blue', metric: sophos.no_sophos_mac, title: 'MacOS not protected', location:'sophos?os=mac' }
                            ]
                    }) }

                    { KPICardMetricsWithPercentIncrease({
                        title: 'AWS Compliance Summary',
                        title_metric: conformance_pack_summary.pack_overall_average.toFixed(2) + "%",
                        today: conformance_pack_summary.pack_overall_average,
                        first_month: aws_config_first_of_month.at(-1),
                        title_icon: ClipboardDocumentCheckIcon,
                        title_icon_color: KPIDecorationColor(conformance_pack_summary.pack_overall_average, 95, 90, true),
                        title_location: '/aws_config',
                        color: KPIDecorationColor(conformance_pack_summary.pack_overall_average, 95, 90, true),
                        items: [
                            { icon: LockClosedIcon, icon_color: 'green', metric: aws_config_summary.compliant_resources, title: 'Compliant Resources'},
                            { icon: LockOpenIcon, icon_color: 'red', metric: aws_config_summary.non_compliant_resources, title: 'Non-compliant Resources'},
                        ]
                    })}

                    {KPICardMetrics({
                        items: []
                    })}

                    {/* { VulnsStackedBarChart({title: "Vulnerabilities per month",*/}
                    {/*    subtitle: "Over the past 6 months",*/}
                    {/*    data: vuln_tracker.month_severities,*/}
                    {/*    months: -6*/}
                    {/*})}*/}

                    { BitsightLineGraph({data: this.state.bitsight, title: "Bitsight Rating"})}

                </Grid>

            );
        }
    }
}
