import {Badge, Button} from '@tremor/react';
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import React from "react";
import {set_logged_out} from "../auth";


export function TooltipText(props){
    return (
        <Badge
            color="neutral"
            size="sm"
            tooltip={props.tooltip}
            className="mt-0">
            {props.text}
        </Badge>
    )
}

export function DeeplinkArrow(props){
    return (
        <Button
                className='hiddenPrint'
                variant='light'
                icon={ChevronRightIcon}
                iconPosition='right'
                size={props.size || 'sm'}
                color="blue"
                onClick={() => window.location = props.location}>
            {props.text}
        </Button>
    )
}

export function AccountRedirect(props){

    return (
        <Button
            className='hiddenPrint'
            variant='light'
            iconPosition='right'
            size={props.size || 'sm'}
            color="blue"
            onClick={() => {
                window.location = props.location;
                localStorage.setItem('account_id', `${props.text}`);}}>
            {props.text}
        </Button>)
}
