import React from 'react';

import {
    Badge,
    Card,
    Col,
    Flex,
    Grid,
    MultiSelectBox,
    MultiSelectBoxItem,
    Italic,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title
} from '@tremor/react';

import {get_url} from '../../const.js'
import withLocation from "../../withLocation";
import {getFilterFromGetParams} from "../utils/query_utils";
import {KPIDonut, KPIBarChart} from "../kpi_components";
import {LoadingComponent, NoDataComponent} from "../status_components";


class SophosTable extends React.Component {

    state = {
        sophos: null,
        metrics: null,
        dates: null,
        nameFilter: getFilterFromGetParams(this.props, 'device', null),
        os: getFilterFromGetParams(this.props, 'os', []),
    };

    componentDidMount() {
        fetch(get_url() + 'api/sophos')
            .then((response) => response.json())
            .then((sophos) => {
                this.setState({sophos: sophos})
            })
            .catch((error) => {
                console.error(error);
            });

        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({metrics: metrics})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/dates')
            .then((response) => response.json())
            .then((dates) => {
                this.setState({dates: dates})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.sophos === null || this.state.metrics === null || this.state.dates === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.sophos.length === 0 || this.state.metrics === 0) {
            return (
                <NoDataComponent/>
            );
        } else {

            let dates = this.state.dates
            const sophos = this.state.metrics.sophos;
            const colors = ["gray", "zinc", "neutral", "red", "orange", "lime", "green", "emerald", "teal", "sky", "blue", "yellow", "cyan", "violet", 
                            "slate", "amber","cyan", "rose", "fuchsia", "green", "violet", "pink", "stone"
            ]
            const osData = [
                { name: "Windows", "Protected": sophos.sophos_platforms["Windows"], "Not Protected": sophos.no_sophos_windows },
                { name: "Linux",   "Protected": sophos.sophos_platforms["Linux"],   "Not Protected": sophos.no_sophos_linux },
                { name: "MacOS",   "Protected": sophos.sophos_platforms["MacOS"],   "Not Protected": sophos.no_sophos_mac }
            ]

            // create list of sophos versions per severity for Tremor donut to ingest
            let sophos_endpoint_versions = []
            for (let version of Object.keys(sophos.sophos_versions)) {
                sophos_endpoint_versions.push({name: version, value: sophos.sophos_versions[version]})
            }

            let active_monitoring = this.state.sophos
                .filter(item => !this.state.nameFilter || item.device.toLowerCase().includes(this.state.nameFilter.toLowerCase()))
                .filter(item => this.state.os.length === 0 || this.state.os.includes(item.os.toString()));

            return (
                <>
                    <Grid numColsMd={2} numColsLg={4} className="gap-x-6 gap-y-6 mt-6">
                        <Col numColSpanMd={1} numColSpanLg={2}>
                            {KPIDonut({
                                cardWidth: "max-w-6xl",
                                title: 'Endpoints Protected: ' + (((sophos.total_endpoints-sophos.total_missing_sophos)/sophos.total_endpoints)*100).toFixed(0)+"%", 
                                subtitle: (sophos.total_endpoints-sophos.total_missing_sophos) + '/' + sophos.total_endpoints + ' endpoints with Sophos running',
                                data: [
                                    {'name': "Protected", value: sophos.total_endpoints-sophos.total_missing_sophos},
                                    {'name': "Not Protected", value: sophos.total_missing_sophos},
                                ],
                                'legend': true,
                                'colors': ["emerald", "red"]
                            })}
                        </Col>

                        <Col numColSpanMd={1} numColSpanLg={2}>
                            {KPIDonut({
                                cardWidth: "max-w-6xl",
                                title: 'Endpoints Not Protected: ' + (((sophos.total_missing_sophos)/sophos.total_endpoints)*100).toFixed(0)+"%", 
                                subtitle: (sophos.total_missing_sophos) + '/' + sophos.total_endpoints + ' endpoints with Sophos not running',
                                data: [
                                    {'name': "Windows", value: sophos.no_sophos_windows},
                                    {'name': "Linux", value: sophos.no_sophos_linux},
                                    {'name': "MacOS", value: sophos.no_sophos_mac}
                                ],
                                'legend': true,
                                'colors': ["teal", "cyan", "stone"]
                            })}
                        </Col>

                        <Col numColSpanMd={2} numColSpanLg={2}>
                            {KPIBarChart({
                                cardWidth: "max-w-6xl",
                                title: "Operating Systems Overview",
                                subtitle: "Endpoint protection per operating system",
                                data: osData,
                                index: "name",
                                categories: ["Protected", "Not Protected"],
                                colors: ["emerald", "red"],
                                legend: true,
                                stack: true,
                                layout: "vertical"
                            })}
                        </Col>

                        <Col numColSpanMd={2} numColSpanLg={2}>
                            {KPIDonut({
                                cardWidth: "max-w-6xl",
                                title: 'Operating Systems Protected', 
                                subtitle: 'Platform OS versions with Sophos running',
                                data: sophos_endpoint_versions,
                                'legend': true,
                                'colors': colors
                            })}
                        </Col>
                    </Grid>

                    <Card className="mt-6">
                        <Flex justifyContent='end'>
                            <div>
                                <Text><Italic>Sophos data updated on: {dates.get_latest_date_sophos_endpoints}</Italic></Text>
                                <Text><Italic>Browser data updated on: {dates.get_latest_date_managed_browsers}</Italic></Text>
                            </div>
                        </Flex>
                        <Flex>
                            <Flex justifyContent='start'>
                                <Title>Endpoints without Sophos: </Title><Text>&nbsp; {active_monitoring.length} / {sophos.total_missing_sophos}</Text>
                            </Flex>
                        </Flex>

                        {this.renderFilters()}

                        <Table className="mt-5">
                            {this.renderHeader()}
                            <TableBody>
                                {active_monitoring.map((item) => this.renderRow(item))}
                            </TableBody>
                        </Table>
                    </Card>
                </>
            );
        }
    }

    renderFilters() {
        let os = Array.from(new Map(this.state.sophos.map((item) => [item["os"], item["os"]])).values());

        return (

            <div>
                <Text>Select filters</Text>
                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <input type="text" placeholder="Filter" onChange={e => {
                            this.setState({nameFilter: e.target.value})
                        }
                        }/>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({os: value})}
                            placeholder="OS"
                            className="max-w-xs"
                        >
                            {os.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item}/>
                            ))}
                        </MultiSelectBox>
                    </Flex>
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell>
                        Endpoint
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Operating System
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Endpoint protected?
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderRow(item) {
        return (
            <TableRow key={item.device}>
                <TableCell>
                    {item.device}
                </TableCell>
                <TableCell className="text-center">
                    {item.os}
                </TableCell>
                <TableCell className="text-center">
                    <Badge color="red">✗</Badge>
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(SophosTable)
