export function getFilterFromGetParams(props, parameter, defaultParam){
    const { search } = props.location;
    const query = new URLSearchParams(search);

    if (!query.has(parameter)) {
        // Return default if parameter is not in the request
        return defaultParam;
    }

    // Enforce lowercase in parameters
    let value = query.get(parameter).toLowerCase();
    if (Array.isArray(defaultParam)){
        // If we are expecting an array use split on the string
        return value.split(',');
    }
    else{
        // Otherwise return the plain string
        return value;
    }
}
